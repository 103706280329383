import { Http, setToken } from '@/helpers'
import { IRefreshTokenReq } from './types'

export const refreshTokenReq = async (refreshToken: string) =>
  Http.post<IRefreshTokenReq>(
    '/token/refresh',
    {
      refresh: refreshToken
    },
    {
      isRefreshToken: true
    }
  ).then((data) => {
    setToken(data.data.access)

    return data
  })
