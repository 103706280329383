import { RouteRecordRaw } from 'vue-router'
import ProjectView from '@/views/project/ProjectView.vue'
import MainPlayer from '@/components/Players/MainPlayer.vue'

const projectRoutes: Array<RouteRecordRaw> = [
  {
    path: '/projects/:id',
    name: 'project',
    component: ProjectView
  },
  {
    path: '/player/:media',
    name: 'project-player',
    component: MainPlayer,
    meta: {
      layout: 'Player'
    }
  }
]

export default projectRoutes
