import { Http } from './http'
import { getToken } from './token'

const API_RESTORE = '/users/new_password'

export const middleware = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isGuest: function (to: any, from: any, next: any) {
    if (getToken()) next({ path: '/' })
    next()
  }
}

export const restore = async (email: string) => {
  const result: { result: object; error: object; isSend: boolean } = {
    result: {},
    error: {},
    isSend: false
  }
  try {
    const response = await Http.post(API_RESTORE, { email: email })
    result.result = response.data
    result.isSend = true
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response) {
      // eslint-disable-next-line no-return-assign
      return (result.error = e.response.data.error)
    }
    result.error = e
  }

  return result
}
