import { Http, clearToken, getRefreshToken } from '@/helpers'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAuthStore = defineStore('auth-store', () => {
  const token = ref<string | null>(localStorage.getItem('token'))
  const username = ref<string | null>(localStorage.getItem('username'))

  const isAuth = computed(() => !!token.value)

  const setToken = (result: { token: string; username: string }) => {
    Http.defaults.headers.Authorization = result.token
    localStorage.setItem('token', result.token)
    localStorage.setItem('username', result.username)
    token.value = result.token
    username.value = result.username
  }

  const removeToken = () => {
    clearToken()
    token.value = null
    username.value = null
  }

  const AUTH = '/login'

  const login = async (form: { username: string; password: string }) => {
    const result: {
      result: object
      error: { errorCode: string; errorMessage: string }
    } = {
      result: {},
      error: {
        errorCode: '',
        errorMessage: ''
      }
    }
    try {
      const response = await Http.post(AUTH, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      result.result = response.data
      // ESLINT-ERROR: Старая реализация
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response) {
        result.error = e.response.data.non_field_errors
        return result
      }
      result.error = e
    }
    return result
  }
  // ESLINT-ERROR: Старая реализация
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAuth = (payload: any) => {
    if (Object.keys(payload.result).length) {
      setToken(payload.result)
    } else {
      removeToken()
    }
  }
  const logout = () => {
    removeToken()
    window.location.href = '/'
  }

  return {
    token,
    isAuth,
    username,
    setToken,
    removeToken,
    login,
    setAuth,
    logout
  }
})
