<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: ''
  }
})
</script>

<template>
<div class="base-line__content">
  <p>{{props.text}}</p>
</div>
</template>

<style scoped lang="less">
.base-line {
  &__content {
    font-family: Unbounded, system-ui;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: var(--typography-primary-color);
    background: var(--secondary-color);
    display: flex;
    p {
      padding: 10px 10px;
      margin: 0;
    }
  }
}
</style>
