<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  color: {
    type: String,
    default: 'default'
  },
  bg: {
    type: String,
    default: 'none'
  },
  border: {
    type: String,
    default: 'none'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  upperCase: {
    type: Boolean,
    default: false
  }
})
</script>

<template>
<button :disabled="props.disabled" :class="['btn', `bg-${props.bg}`, `border-${props.border}`, `color-${props.color}`]"><slot /></button>
</template>

<style scoped lang="less">
.btn {
  padding: 10px 14px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  cursor: pointer;
  background: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Wix Madefor Display,sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.btn[disabled],
.btn:disabled {
  cursor: not-allowed;
  background: #565656;
  color: #000000;
}
.bg {
  &-gradient {
    background-image: var(--gradient-2);
    background-origin: border-box;
  }
  &-gradient-green {
    background-image: var(--gradient);
    background-origin: border-box;
  }
  &-theme {
    background: var(--button-primary-color);
    color: var(--button-secondary-color);
  }
}
.border {
  &-none {
    border: none;
  }
  &-gradient {
    position: relative;
    border: none;
    &:before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 10px;
      padding: 1px;
      background:var(--gradient-2);
      -webkit-mask:
        var(--gradient-2) content-box,
        var(--gradient-2);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
.color {
  &-gradient {
    background:var(--gradient-2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.large {
  padding: 19px 24px;
}
.rounded {
  border-radius: 20px;
  &.border {
    &-gradient {
      &:before {
        border-radius: 20px;
      }
    }
  }
}
</style>
