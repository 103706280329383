<script lang="ts" setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: 'like'
  },
  width: {
    type: Number,
    default: 50
  },
  height: {
    type: Number,
    default: 50
  },
  viewBox: {
    type: String,
    default: '0 0 50 50'
  },
  prefix: {
    type: String,
    default: 'icon'
  }
})
const href = computed(() => {
  /* eslint-disable */
  let icon = require(`@/assets/icons/${props.name}.svg`)
  if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
    icon = icon.default
  }

  return window.location.origin+icon.url
})
</script>
<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    class="icon-sprite"
  >
    <use :xlink:href="href" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>
