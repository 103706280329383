
export const formatTime = (time: number, hours: boolean): string => {
  if (hours) {
    const h = Math.floor(time / 3600)
    time = time - h * 3600

    const m = Math.floor(time / 60)
    const s = Math.floor(time % 60)

    return zeros(h, 2) + ':' + zeros(m, 2) + ':' + zeros(s, 2)
  } else {
    const m = Math.floor(time / 60)
    const s = Math.floor(time % 60)

    return zeros(m, 2) + ':' + zeros(s, 2)
  }
}

const zeros = (n:number, c:number) => {
  let nz = '' + n
  while (nz.length < c) {
    nz = '0' + nz
  }
  return nz
}
