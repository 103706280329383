<template>
  <div class="gradient-line"></div>
</template>

<style scoped lang="less">
.gradient-line {
  height: 40px;
  width: 100%;
  background: var(--gradient-2);
}
</style>
