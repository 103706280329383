<script setup lang="ts">

import BaseLine from '@/components/Lines/BaseLine.vue'
import HomeSlider from '@/components/Sliders/HomeSlider.vue'
import HomeCatalog from '@/components/Catalogs/HomeCatalog.vue'
import CreateAccount from '@/components/Register/CreateAccount.vue'
import CreateAccount2 from '@/components/Register/CreateAccount2.vue'
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)
</script>

<template>
<div class="base-line">
  <BaseLine text="твое личное время"/>
</div>
<div class="home-slider">
  <HomeSlider />
</div>
<CreateAccount v-if="!isAuth"/>
<div class="home-catalog">
  <HomeCatalog />
</div>
<CreateAccount2 v-if="!isAuth"/>
</template>
