import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getCatalog, searchMedia } from '@/api'

export const useCatalogStore = defineStore('catalog-store', () => {
  const catalog = ref()
  const loaded = ref(false)
  const error = ref('')

  const setCatalog = (value: any) => {
    loaded.value = true
    catalog.value = value
  }
  const setCatalogError = (value: string) => {
    error.value = value
  }

  const getCatalogAsync = async () => {
    try {
      const { data } = await getCatalog()
      data.results.map(async (item: any) => {
        item.url = item.url.replace('/playlists/', '')
        const imageUrl = await searchMedia(item.url.toString().toLowerCase()).then(response => {
          if (!response.data.count) {
            return item.thumbnail_url
          }
          return response.data.results[0]?.thumbnail_url
        })
        item.thumbnail_url = imageUrl
        try {
          const description = item.description.toString().replace(/\\n/g, '<br>')
          item.description = JSON.parse(description)
        } catch (e) {
          console.log('JSON parse error')
        }
      })
      setCatalog(data)
      return Promise.resolve(data)
    } catch (errorCatch) {
      setCatalogError('error')
      console.log(errorCatch)
      // return Promise.reject(errorCatch)
    }
  }

  return {
    catalog,
    loaded,
    error,
    setCatalog,
    setCatalogError,
    getCatalogAsync
  }
})
