export const TOKEN_KEY = 'token'
export const TOKEN_REFRESH_KEY = 'refresh'

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(TOKEN_REFRESH_KEY)
}

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(TOKEN_REFRESH_KEY, refreshToken)
}

export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const getRefreshToken = () => localStorage.getItem(TOKEN_REFRESH_KEY)

export const getBearerTokenHeader = (token: string) => token
