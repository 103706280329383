<script setup lang="ts">
import { defineProps } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { IPlaylistItem } from '@/api'

const props = defineProps<{
  media: IPlaylistItem;
  overlay: boolean;
}>()
</script>

<template>
  <div class="player-preview">
    <img class="player-preview__background" :src="props.media?.thumbnail_url" :alt="props.media?.title" />
    <div :class="props.overlay ? 'player-preview__overlay player-preview__overlay-black':'player-preview__overlay'">
      <CircleButton class="btn-player__action play" bg="none" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
    </div>
  </div>
</template>

<style scoped lang="less">
.player-preview {
  position: relative;
  &__background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-black {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
    }
    .bg-none {
      opacity: 1;
      color: #ffffff;
      border: #ffffff solid 2px;
    }
  }
}
</style>
