<template>
<div class="wrapper">Реклама</div>
</template>

<style scoped lang="less">
.wrapper {
  background: #FFCC00;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-family: Unbounded;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
</style>
