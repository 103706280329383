<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue'
import IconBase from '@/components/Icons/IconBase.vue'
const props = defineProps<{
  modelValue: string;
  type: string;
  placeholder: string;
}>()

defineEmits(['update:modelValue'])

const showPassword = ref(false)

const getType = () => {
  if (props.type !== 'password') {
    return props.type
  }
  return showPassword.value ? 'text' : 'password'
}
const togglePassword = () => {
  showPassword.value = !showPassword.value
}
</script>

<template>
  <div class="input p-relative d-flex align-items-center">
    <input
      :type="getType()"
      :value="props.modelValue"
      :placeholder="props.placeholder || ''"
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    />
    <button
      v-if="type === 'password'"
      type="button"
      class="password_toggle"
      @click="togglePassword"
    >
      <IconBase :name="showPassword ? 'close' : 'open'" view-box="0 0 24 24" :width="24" :height="24"/>
    </button>
  </div>
</template>

<style scoped lang="less">
input {
  width: 100%;
  padding: 19px 24px;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid var(--input-border);
  background: transparent;
  text-align: center;
  color: var(--input-color);
  background: var(--input-bg);
  z-index: 1;
}
::-webkit-input-placeholder {
  color: var(--input-placeholder);
}
:-moz-placeholder {
  color: var(--input-placeholder);
  opacity:  1;
}
::-moz-placeholder {
  color: #ffffff;
  opacity:  1;
}
:-ms-input-placeholder {
  color: var(--input-placeholder);
}
::-ms-input-placeholder {
  color: var(--input-placeholder);
}

::placeholder {
  color: var(--input-placeholder);
}
.password_toggle {
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  z-index: 1;
  color: #ffffff;
  cursor: pointer;
}
</style>
