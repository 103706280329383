<script setup lang="ts">
import IconBase from '@/components/Icons/IconBase.vue'
import { defineProps } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: 'like'
  },
  iconWidth: {
    type: Number,
    default: 50
  },
  iconHeight: {
    type: Number,
    default: 50
  },
  viewBox: {
    type: String,
    default: '0 0 50 50'
  },
  bg: {
    type: String,
    default: 'gray'
  }
})
</script>

<template>
<button :class="['btn', `bg-${props.bg}`]">
  <IconBase :name="props.name" :view-box="props.viewBox" :width="props.iconWidth" :height="props.iconHeight"/>
</button>
</template>

<style scoped lang="less">
.btn {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &-player,
  &-player__action {
    width: 120px;
    height: 120px;
  }
}
.bg {
  &-gray {
    background: #7c7c7c;
    border: 2px solid #7c7c7c;
    color: #ffffff;
  }
  &-none {
    background: transparent;
    border: 2px solid #7c7c7c;
    color: #7c7c7c;
    opacity: 0.6;
  }
  &-empty {
    background: transparent;
    border: 2px solid transparent;
    color: #ffffff;
  }
  &-gradient {
    background: var(--gradient-2);
    color: #ffffff;
  }
  &-theme {
    background: var(--secondary-color);
    color: var(--typography-secondary-color);
  }
}
</style>
