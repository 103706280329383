import { defineStore } from 'pinia'
import { IUser } from './types'
import { useAuthStore } from '../auth'
import { ref } from 'vue'
import { getUserInfoById } from '@/api'

export const useUserStore = defineStore('user-store', () => {
  const authStore = useAuthStore()

  const user = ref<IUser | null>(null)
  const loaded = ref(false)
  const error = ref('')

  const setUser = (value: any) => {
    loaded.value = true
    user.value = value
  }
  const setUserError = (value: string) => {
    error.value = value
  }

  const getUserAsync = async () => {
    if (authStore.isAuth && authStore.username) {
      try {
        const data = await getUserInfoById(authStore.username)
        setUser(data.data)

        return Promise.resolve(data.data)
      } catch (errorCatch) {
        setUserError('error')
        console.log(errorCatch)
        // return Promise.reject(errorCatch)
      }
    }

    return undefined
  }

  return {
    user,
    loaded,
    error,
    setUser,
    setUserError,
    getUserAsync
  }
})
