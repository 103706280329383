<template>
  <component :is="layout">
    <router-view v-if="layoutLoad" />
  </component>
</template>

<script setup lang="ts">
import { computed, markRaw, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

type AppLayoutName = 'Default' | 'Player'

const layout = ref()
const route = useRoute()
const metaLayout = computed<AppLayoutName>(
  () => (route.meta?.layout as AppLayoutName) || 'Default'
)

const layoutLoad = ref(false)

const loadAppLayout = async (layoutName: AppLayoutName = 'Default') =>
  import(`./layouts/${layoutName}/${layoutName}Layout.vue`).then(
    (module) => module.default
  )

async function setAppLayout (layoutName: AppLayoutName = 'Default') {
  // ESLINT-DISABLE: Старая реализация
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let component: any = null

  layoutLoad.value = false

  await nextTick()

  try {
    component = await loadAppLayout(layoutName)
  } catch (error) {
    console.error(error, 'error app load layout')

    component = await loadAppLayout()
  }

  layout.value = markRaw(component)

  await nextTick()

  layoutLoad.value = true
}

watch(metaLayout, (val) => {
  setAppLayout(val)
})

onMounted(() => {
  setAppLayout(metaLayout.value)
})
</script>
