import { RouteRecordRaw } from 'vue-router'
import LoginView from '@/views/login/LoginView.vue'
import RegisterView from '@/views/login/RegisterView.vue'
import RestoreView from '@/views/login/RestoreView.vue'
import LoginSuccessView from '@/views/login/LoginSuccessView.vue'
import { middleware } from '@/helpers/auth'

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: [middleware.isGuest]
  },
  {
    path: '/login/register',
    name: 'login-register',
    component: RegisterView,
    beforeEnter: [middleware.isGuest]
  },
  {
    path: '/login/restore',
    name: 'login-restore',
    component: RestoreView,
    beforeEnter: [middleware.isGuest]
  },
  {
    path: '/login/success',
    name: 'login-success',
    component: LoginSuccessView
  }
]

export default authRoutes
