<script setup lang="ts">

import CircleButton from '@/components/Buttons/CircleButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import BackLine from '@/components/Lines/BackLine.vue'
import router from '@/router'
import { ref } from 'vue'

const login = ref('')
const password = ref('')
</script>

<template>
  <BackLine>
    <CircleButton @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <div class="create-account px-10 mb-20">
    <div class="create-account__wrapper text-center pa-40">
      <h2 class="mb-10">Регистрация</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">Авторизация</h5>
      <div class="create-account__form">
        <BaseInput v-model="login" class="mb-10 full-width" type="text" placeholder="Ник / Почта"/>
        <BaseInput v-model="password" class="mb-10 full-width" type="password" placeholder="Пароль"/>
        <router-link :to="{name: 'login-success'}"><BaseButton class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Зарегистрироваться</BaseButton></router-link>
      </div>
      <div class="text-gradient d-inline-flex flex-direction-column">
        <h5 class="text-center mb-10"><router-link :to="{name: 'login-restore'}">Забыли пароль? Восстановить</router-link></h5>
        <h6 class="text-center mb-10"><span>Продолжая, вы принимаете</span> условия использования<span>,<br>а также подтверждаете, что ознакомились<br>с документом</span> «Политика конфиденциальности».</h6>
        <br>
        <h5><span>Уже есть аккаунт?</span></h5>
        <h5><router-link :to="{name: 'login'}">Войти</router-link></h5>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.create-account {
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    position: relative;
    border: none;
    .text-gradient {
      h6, h5 {
        background:var(--gradient-1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        span {
          background: none;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }
}
</style>
