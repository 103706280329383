import axios, {
  isAxiosError,
  type AxiosInstance,
  type CreateAxiosDefaults
} from 'axios'
import router from '@/router/index'
import {
  clearToken,
  getBearerTokenHeader,
  getRefreshToken,
  getToken
} from './token'
import { refreshTokenReq } from '@/api'
import { useAuthStore } from '@/stores/auth'
import { API_URL } from '@/helpers/globalVars'

/**
 * @description Чтобы прокидывать кастомные переменные
 */
declare module 'axios' {
  export interface AxiosRequestConfig {
    isRefreshToken?: boolean
    _retry?: boolean
    noErrorMessage?: boolean
    noErrorMessageCodes?: number[]
  }
}

export type AxiosDefaultHeaders = CreateAxiosDefaults['headers']

export const getAxiosInstance = (
  baseCurURL: string,
  headers: AxiosDefaultHeaders = {}
): AxiosInstance => {
  const token = getToken()

  const headerStrings: AxiosDefaultHeaders = token
    ? { Authorization: getBearerTokenHeader(token) }
    : {}

  const HttpInst = axios.create({
    baseURL: baseCurURL,
    withCredentials: false,
    timeout: 500000000,
    headers: {
      ...headerStrings,
      ...headers
    } as AxiosDefaultHeaders
  })

  HttpInst.interceptors.response.use(
    (response) => Promise.resolve(response),
    async (error) => {
      const authStore = useAuthStore()

      if (isAxiosError(error)) {
        const originalRequest = error.config

        if (!originalRequest || !error.config) {
          return Promise.reject(error)
        }

        const response = error?.response
        const errorCode = response?.data?.error?.error_code

        if (originalRequest?.isRefreshToken) {
          clearToken()
          router.push('/login')
          return Promise.reject(error)
        }

        if (response?.status === 401 && !originalRequest._retry) {
          // ESLINT-ERROR: Старая реализация
          // eslint-disable-next-line no-param-reassign
          error.config._retry = true
          try {
            const refreshToken = getRefreshToken()

            const { data: responseData } = await refreshTokenReq(
              String(refreshToken)
            )

            // ESLINT-DISABLE: Старая реализация
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const token = responseData.access

            authStore.setAuth({
              result: { access: token, refresh: refreshToken }
            })

            HttpInst.defaults.headers.Authorization =
              getBearerTokenHeader(token)
            originalRequest.headers.Authorization = getBearerTokenHeader(token)

            return HttpInst.request(originalRequest)
          } catch (e) {
            clearToken()
            router.push('/login')
            return Promise.reject(error)
          }
        } else if (response?.status === 400 && errorCode === 'token_error') {
          authStore.removeToken()
          router.push('/login')
        }
      }

      return Promise.reject(error)
    }
  )
  return HttpInst
}

export const Http = getAxiosInstance(API_URL)
