import { RouteRecordRaw } from 'vue-router'
import ProfileView from '@/views/profile/ProfileView.vue'
import ProfileInterfaceView from '@/views/profile/ProfileInterfaceView.vue'

const profileRoutes: Array<RouteRecordRaw> = [
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/profile/interface',
    name: 'profile-interface',
    component: ProfileInterfaceView
  }
]

export default profileRoutes
