<template>
<div class="back-line d-flex align-items-center">
  <slot />
</div>
</template>

<style scoped lang="less">
.back-line {
  height: 40px;
  color: var(--typography-primary-color);
  background: var(--secondary-color);
}
</style>
