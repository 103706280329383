<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  bg: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <div class="border-text pa-10">
    <div class="border-text__wrapper pa-20" :class="props.bg">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="less">
.border-text {
  &__wrapper {
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}
</style>
